<div class="main-order-review">
  <ng-container *ngIf="revisionRequested">
    <div class="breadcrumbs" *ngIf="!token">
      <a routerLink="/"><img src="../../../assets/images/home-2.svg" alt="" /></a> /
      <a routerLink="../..">Orders</a> /
      <p>Order View [{{ orderNo }}]</p>
      /
      <p>Request Revision</p>
    </div>

    <div class="main-order-review__row" [ngClass]="{ 'row-reverse': isRightSidePreference, 'revision-wrapper': revisionRequested }" *ngIf="revisionRequested">
      <div class="left-pane-wrapper">
        <div class="main-order-review__left header-left">
          <button class="button-outline btn-wrap" type="button" (click)="goBackToFindingOrReview()">
            <app-svg [svgPath]="'../../../../assets/images/arrow-left.svg'"></app-svg>Go back to {{stepForRevision === 0 ? 'Order Review' : 'Review Findings' }}</button>
          <mat-form-field subscriptSizing="dynamic">
            <mat-select [value]="revisionList[0]" [(ngModel)]="selectedRevision">
              <mat-option *ngFor="let revision of revisionList" [value]="revision">{{revision.name}}</mat-option>
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
          </mat-form-field>
        </div>
        <div class="main-order-review__right header-right">
          <div class="main-order-review__action-dropdown" (click)="expendOrderInfo()">
            <app-svg [svgPath]="'../../../../assets/images/info.svg'"></app-svg>
          </div>
          <div class="switch-wrapper">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="revisionSendToClient" [disabled]="stepForRevision === 1">
              <span class="slider round"></span>
            </label>
            <small>Send Revision to Client</small>
          </div>
          <button class="btn-primary" type="button" (click)="sendRevision(0)" *ngIf="stepForRevision === 0">{{revisionSendToClient ? 'Next' : 'Send Revision'}}</button>
          <button class="btn-primary" type="button" (click)="sendRevision(1)" *ngIf="stepForRevision === 1">Send Revision</button>
        </div>
      </div>
      <div class="right-pane-wrapper">
        <div class="main-order-review__left revision-Left" *ngIf="stepForRevision === 0">
          <div class="revision-left-header">
            <h5>Review Rejected Findings</h5>
            <p><strong>Count:</strong>{{ rejectedfindings && rejectedfindings === 1 ? rejectedfindings + ' record' : rejectedfindings + ' records' }}</p>
          </div>
          <app-order-findings-view
            [findingsData]="findingsData"
            [resolvedFindings]="resolvedfindings"
            [reviewId]="reviewId"
            [orderStatus]="orderStatus"
            [rejectedfindings]="rejectedfindings"
            (findingResolved)="updateResolvedfindingsCount($event)"
            (updateFindingsList)="getOrderDetails($event.reloadDoc, $event.isActionPerformed)"
            [toggleState]="toggleState"
            [isPreview]="isPreview"
            [totalFindings]="totalfindings"
            [originalFindingsData]="originalFindingsData"
            [findingsTabSelection]="findingsTabSelection"
            (applyFilterOnFindings)="getFilteredFindings(findingsTabSelection, $event)"
            [isActionPerformed]="isActionPerformed"
            [xpathToScroll]="xpathToScroll"
            [enableFindingClick]="enableFindingClick"
            [revisionRequested]="revisionRequested"
            (backToReport)="togglePdfView()"
            (draggedFinding)="draggedFindingPlace($event)"
            [vendorAndReviewer]="vendorAndReviewer"
            [sortRevisionArray]="sortRevisionArray"
            (updateSortedList)="updateSortedList($event)"
            (findingId)="findingIdRemove($event)"
            (backToOrderDetail)="backToOrderDetail($event)"
          ></app-order-findings-view>
        </div>
        <div class="main-order-review__right" [ngClass]="{'revision-right': revisionRequested}">
          <app-pdf-view
            [expendInfo]="expendInfo"
            [orderReviewHeaderData]="orderReviewHeaderData"
            [verifiedAddress]="addressVerified"
            [clientDetail]="clientDetail"
            [stepForRevision]="stepForRevision"
          ></app-pdf-view>

          <app-revision-request
            [originalFindingsData]="originalFindingsData"
            [sortRevisionArray]="sortRevisionArray"
            [draggedFindings]="draggedFindings"
            [stepForRevision]="stepForRevision"
            (backToReport)="togglePdfView()"
            (sortedArray)="sortedArray($event)"
          ></app-revision-request>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="main-order-review__row" [ngClass]="{ 'row-reverse': isRightSidePreference }" *ngIf="!revisionRequested">
    <div class="main-order-review__left" [ngClass]="{'isExpanded': expendSidebar}">
      <div class="collapse-btn" (click)="expendSidebar = !expendSidebar" [ngClass]="{ 'd-none': expendSidebar }"><app-svg [svgPath]="'../../../assets/images/cirlce-cheveron.svg'"></app-svg></div>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" [selectedIndex]="selectedTab" (selectedTabChange)="tabIndex = $event.index">
        <mat-tab>
          <ng-template mat-tab-label>
            <app-svg [svgPath]="'../../../../assets/images/find-in.svg'"></app-svg>
            <span>Findings</span>
          </ng-template>
          <app-findings-stats-view
            [hidden]="revisionRequested"
            [totalfindings]="totalfindings"
            [resolvedfindings]="resolvedfindings"
            [orderId]="orderId"
            [orderNo]="orderNo"
            [isPreview]="isPreview"
            [activeFilter]="findingsTabSelection"
            [rejectedFindings]="rejectedfindings"
            [orderStatus]="orderStatus"
            [countData]="countData"
            [vendorAndReviewer]="vendorAndReviewer"
            (backToReport)="togglePdfView()"
            (findingsType)="getFilteredFindings($event, filteredFindings)"
            (updateOrderStatus)="getOrderDetails($event.reloadDoc, $event.isActionPerformed)"
          ></app-findings-stats-view>
          <app-order-findings-view
            [findingsData]="findingsData"
            [resolvedFindings]="resolvedfindings"
            [reviewId]="reviewId"
            [orderStatus]="orderStatus"
            [rejectedfindings]="rejectedfindings"
            (findingResolved)="updateResolvedfindingsCount($event)"
            (updateFindingsList)="getOrderDetails($event.reloadDoc, $event.isActionPerformed)"
            [toggleState]="toggleState"
            [isPreview]="isPreview"
            [totalFindings]="totalfindings"
            [originalFindingsData]="originalFindingsData"
            [findingsTabSelection]="findingsTabSelection"
            (applyFilterOnFindings)="getFilteredFindings(findingsTabSelection, $event)"
            [isActionPerformed]="isActionPerformed"
            [xpathToScroll]="xpathToScroll"
            [enableFindingClick]="enableFindingClick"
            [revisionRequested]="revisionRequested"
            (backToReport)="togglePdfView()"
            [vendorAndReviewer]="vendorAndReviewer"
            [sortRevisionArray]="sortRevisionArray"
            (updateSortedList)="updateSortedList($event)"
            (findingId)="findingIdRemove($event)"
            (backToOrderDetail)="backToOrderDetail($event)"
          ></app-order-findings-view>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <app-svg [svgPath]="'../../../../assets/images/file-document-outline.svg'"></app-svg>
            <span>Documents</span>
          </ng-template>
          <app-order-review-documents
            [documents]="documents"
            [data]="data"
            [isPreview]="isPreview"
          ></app-order-review-documents>
        </mat-tab>
        <mat-tab *ngIf="userConfig.checkPermissions(permission.MESSAGES_VIEW)">
          <ng-template mat-tab-label>
            <app-svg
              [svgPath]="'../../../../assets/images/message-processing-outline.svg'"
            ></app-svg>
            <span>Messages</span>
          </ng-template>
          <app-order-messages
            [orderId]="orderId"
            [orderDetail]="orderDetail"
            [orderHistory]="orderHistory"
            [unAssigned]="isPreview"
          ></app-order-messages>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <app-svg [svgPath]="'../../../../assets/images/repeat.svg'"></app-svg>
            <div>Revisions</div>
          </ng-template>
          <app-revision-messages [orderId]="orderId"></app-revision-messages>
        </mat-tab>
      </mat-tab-group>
      <div class="escalate-box"  *ngIf="!token && userConfig.checkPermissions(permission.ESCALATE_TO) && tabIndex === 0">
        <button class="btn-primary" [disabled]="isPreview" (click)="openReviwerAssignmentDialog(ModalTitle.ESCALATE_TO, 'Escalate', 'Cancel')"><app-svg [svgPath]="'../../../../assets/images/esclate.svg'"></app-svg>Escalate</button>
      </div>
    </div>
    <div class="main-order-review__right">
      <div class="main-order-review__header-wrap isDesktop">
        <div class="main-order-review__order-header">
          <div class="breadcrumbs" *ngIf="!token">
            <a routerLink="/"><img src="../../../assets/images/home-2.svg" alt="" /></a> /
            <a routerLink="../..">Orders</a> /
            <p>Order View [{{ orderNo }}]</p>
            <div class="order-review-header__col">
              <div class="order-review-header__order-number">
                <app-order-status [orderStatus]="orderStatus"></app-order-status>
              </div>
            </div>
          </div>

          <mat-form-field class="custom-select" subscriptSizing="dynamic">
            <div matPrefix *ngIf="reviewerCtrl.value && !reviewerCtrl.value?.public_image && showInitials" class="initials-div"
                 [ngStyle]="{'background-color': sharedService.getRandomColor(reviewerCtrl.value?.id), 'color': 'white'}">
              {{ sharedService.generateInitials(reviewerCtrl.value?.first_name, reviewerCtrl.value?.last_name) }}
            </div>
            <img matPrefix class="initials-div" *ngIf="reviewerCtrl.value?.public_image" [src]="reviewerCtrl.value.public_image" />

            <input
              type="text"
              matInput
              [formControl]="reviewerCtrl"
              [matAutocomplete]="auto"
              placeholder="Assign to"
              required
              (ngModelChange)="filterOptions(reviewerCtrl.value)"
              [readonly]="isPreview || !userConfig.checkPermissions(permission.REVIEWER_ASSIGNMENT)"
              (focus)="onFocus()"
            />

            <div mat-icon-button *ngIf="isDropdownOpen && reviewerCtrl.value" matSuffix aria-label="Clear" (click)="clearSelection()" class="clearBtn">
              <app-svg [svgPath]="'../../../../assets/images/cross-outline.svg'"></app-svg>
            </div>
            <mat-icon matSuffix *ngIf="!isDropdownOpen && !isPreview && userConfig.checkPermissions(permission.REVIEWER_ASSIGNMENT)">keyboard_arrow_down</mat-icon>

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTitle.bind(this)"
              (optionSelected)="openReviwerAssignmentDialog('A message for reviewer', 'Send', 'Skip')" (opened)="isDropdownOpen = true" (closed)="closeDropdownForReviewer()">

              <mat-optgroup label="Admins" *ngIf="filteredAdmins.length > 0">
                <mat-option *ngFor="let admin of filteredAdmins" [value]="admin">
                  <div class="initials-div" *ngIf="!admin.image_key"
                       [ngStyle]="{'background-color': sharedService.getRandomColor(admin.id), 'color': 'white'}">
                    {{ sharedService.generateInitials(admin?.first_name, admin?.last_name) }}
                  </div>
                  <img *ngIf="admin.image_key" [appLazyLoadImage]="admin.image_key" [loadImage]="loadImage.bind(this)" alt="{{ admin.first_name }}" [src]="imageCache[admin.image_key]" />
                  {{ admin.first_name }} {{ admin.last_name }}
                </mat-option>
              </mat-optgroup>

              <mat-optgroup label="Managers" *ngIf="filteredManagers.length > 0">
                <mat-option *ngFor="let manager of filteredManagers" [value]="manager">
                  <div class="initials-div" *ngIf="!manager.image_key"
                       [ngStyle]="{'background-color': sharedService.getRandomColor(manager.id), 'color': 'white'}">
                    {{ sharedService.generateInitials(manager?.first_name, manager?.last_name) }}
                  </div>
                  <img *ngIf="manager.image_key" [appLazyLoadImage]="manager.image_key" [loadImage]="loadImage.bind(this)" alt="{{ manager.first_name }}" [src]="imageCache[manager.image_key]" />
                  {{ manager.first_name }} {{ manager.last_name }}
                </mat-option>
              </mat-optgroup>

              <mat-optgroup label="Reviewers" *ngIf="filteredReviewers.length > 0">
                <mat-option *ngFor="let reviewer of filteredReviewers" [value]="reviewer">
                  <div class="initials-div" *ngIf="!reviewer.image_key"
                       [ngStyle]="{'background-color': sharedService.getRandomColor(reviewer.id), 'color': 'white'}">
                    {{ sharedService.generateInitials(reviewer?.first_name, reviewer?.last_name) }}
                  </div>
                  <img *ngIf="reviewer.image_key" [appLazyLoadImage]="reviewer.image_key" [loadImage]="loadImage.bind(this)" alt="{{ reviewer.first_name }}" [src]="imageCache[reviewer.image_key]" />
                  {{ reviewer.first_name }} {{ reviewer.last_name }}
                </mat-option>
              </mat-optgroup>

            </mat-autocomplete>
          </mat-form-field>

        </div>

        <app-order-review-header
          [page]="'edit-order'"
          [orderNo]="orderNo"
          [source]="source"
          [orderStatus]="orderStatus"
          [rejectedfindings]="rejectedfindings"
          [revisionNumber]="revisionNumber"
          [reviewId]="reviewId"
          [resolvedFindings]="resolvedfindings"
          [totalFindings]="totalfindings"
          (orderHeaderUpdated)="getOrderDetails($event.reloadDoc, $event.isActionPerformed)"
          [toggleState]="toggleState"
          (toggleStateChange)="togglePdfDisplayState()"
          [formHtml]="formHtml"
          [isPreview]="isPreview"
          [isEADactive]="orderReviewHeaderData.isEADactive"
          [isUCDPactive]="orderReviewHeaderData.isUCDPactive"
          (orderInfoCollapse)="expendOrderInfo()"
          [currentUserId]="currentUserId"
          [isRightSidePreference]="isRightSidePreference"
          (updateLayoutPreference)="getConfigurationsFromLS()"
          [eadAppraisalNumber]="eadAppraisalNumber"
          [ucdpAppraisalNumber]="ucdpAppraisalNumber"
          [revisionRequested]="revisionRequested"
          (backToReport)="togglePdfView()"
          [isEADsubmitted]="isEADsubmitted"
          [isUCDPsubmitted]="isUCDPsubmitted"
          [vendorAndReviewer]="vendorAndReviewer"
        ></app-order-review-header>
      </div>
      <div class="main-order-review__header-wrap isTablet">
        <div class="main-order-review__order-header">
          <div class="main-order-review__show-finding-cta"  (click)="expendSidebar = !expendSidebar" [ngClass]="{ 'd-none': !expendSidebar }" >
            <span>Show Findings</span>
            <app-svg [svgPath]="'../../../assets/images/cirlce-cheveron.svg'"></app-svg>
          </div>
          <div class="breadcrumbs" *ngIf="!token">
            <a routerLink="/"><img src="../../../assets/images/home-2.svg" alt="" /></a> /
            <a routerLink="../..">Orders</a> /
            <p>Order View [{{ orderNo }}]</p>
          </div>
        </div>

        <app-order-review-header
          [page]="'edit-order'"
          [orderNo]="orderNo"
          [source]="source"
          [orderStatus]="orderStatus"
          [rejectedfindings]="rejectedfindings"
          [revisionNumber]="revisionNumber"
          [reviewId]="reviewId"
          [resolvedFindings]="resolvedfindings"
          [totalFindings]="totalfindings"
          (orderHeaderUpdated)="getOrderDetails($event.reloadDoc, $event.isActionPerformed)"
          [toggleState]="toggleState"
          (toggleStateChange)="togglePdfDisplayState()"
          [formHtml]="formHtml"
          [isPreview]="isPreview"
          [isEADactive]="orderReviewHeaderData.isEADactive"
          [isUCDPactive]="orderReviewHeaderData.isUCDPactive"
          (orderInfoCollapse)="expendOrderInfo()"
          [currentUserId]="currentUserId"
          [isRightSidePreference]="isRightSidePreference"
          (updateLayoutPreference)="getConfigurationsFromLS()"
          [eadAppraisalNumber]="eadAppraisalNumber"
          [ucdpAppraisalNumber]="ucdpAppraisalNumber"
          [revisionRequested]="revisionRequested"
          (backToReport)="togglePdfView()"
          [isEADsubmitted]="isEADsubmitted"
          [isUCDPsubmitted]="isUCDPsubmitted"
          [vendorAndReviewer]="vendorAndReviewer"
          [expendSidebar]="expendSidebar"
        >
      </app-order-review-header>



        <div class="order-review-header__tablet">
          <div class="order-review-header__col">
            <div class="order-review-header__order-number">
              <app-order-status [orderStatus]="orderStatus"></app-order-status>
            </div>
          </div>



          <mat-form-field class="custom-select" subscriptSizing="dynamic">
            <div matPrefix *ngIf="reviewerCtrl.value && !reviewerCtrl.value?.public_image && showInitials" class="initials-div"
                 [ngStyle]="{'background-color': sharedService.getRandomColor(reviewerCtrl.value?.id), 'color': 'white'}">
              {{ sharedService.generateInitials(reviewerCtrl.value?.first_name, reviewerCtrl.value?.last_name) }}
            </div>
            <img matPrefix class="initials-div" *ngIf="reviewerCtrl.value?.public_image" [src]="reviewerCtrl.value.public_image" />

            <input
              type="text"
              matInput
              [formControl]="reviewerCtrl"
              [matAutocomplete]="auto"
              placeholder="Assign to"
              required
              (ngModelChange)="filterOptions(reviewerCtrl.value)"
              [readonly]="isPreview || !userConfig.checkPermissions(permission.REVIEWER_ASSIGNMENT)"
              (focus)="onFocus()"
            />

            <div mat-icon-button *ngIf="isDropdownOpen && reviewerCtrl.value" matSuffix aria-label="Clear" (click)="clearSelection()" class="clearBtn">
              <app-svg [svgPath]="'../../../../assets/images/cross-outline.svg'"></app-svg>
            </div>
            <mat-icon matSuffix *ngIf="!isDropdownOpen && !isPreview && userConfig.checkPermissions(permission.REVIEWER_ASSIGNMENT)">keyboard_arrow_down</mat-icon>

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTitle.bind(this)"
              (optionSelected)="openReviwerAssignmentDialog('A message for reviewer', 'Send', 'Skip')" (opened)="isDropdownOpen = true" (closed)="closeDropdownForReviewer()">

              <mat-optgroup label="Admins" *ngIf="filteredAdmins.length > 0">
                <mat-option *ngFor="let admin of filteredAdmins" [value]="admin">
                  <div class="initials-div" *ngIf="!admin.image_key"
                       [ngStyle]="{'background-color': sharedService.getRandomColor(admin.id), 'color': 'white'}">
                    {{ sharedService.generateInitials(admin?.first_name, admin?.last_name) }}
                  </div>
                  <img *ngIf="admin.image_key" [appLazyLoadImage]="admin.image_key" [loadImage]="loadImage.bind(this)" alt="{{ admin.first_name }}" [src]="imageCache[admin.image_key]" />
                  {{ admin.first_name }} {{ admin.last_name }}
                </mat-option>
              </mat-optgroup>

              <mat-optgroup label="Managers" *ngIf="filteredManagers.length > 0">
                <mat-option *ngFor="let manager of filteredManagers" [value]="manager">
                  <div class="initials-div" *ngIf="!manager.image_key"
                       [ngStyle]="{'background-color': sharedService.getRandomColor(manager.id), 'color': 'white'}">
                    {{ sharedService.generateInitials(manager?.first_name, manager?.last_name) }}
                  </div>
                  <img *ngIf="manager.image_key" [appLazyLoadImage]="manager.image_key" [loadImage]="loadImage.bind(this)" alt="{{ manager.first_name }}" [src]="imageCache[manager.image_key]" />
                  {{ manager.first_name }} {{ manager.last_name }}
                </mat-option>
              </mat-optgroup>

              <mat-optgroup label="Reviewers" *ngIf="filteredReviewers.length > 0">
                <mat-option *ngFor="let reviewer of filteredReviewers" [value]="reviewer">
                  <div class="initials-div" *ngIf="!reviewer.image_key"
                       [ngStyle]="{'background-color': sharedService.getRandomColor(reviewer.id), 'color': 'white'}">
                    {{ sharedService.generateInitials(reviewer?.first_name, reviewer?.last_name) }}
                  </div>
                  <img *ngIf="reviewer.image_key" [appLazyLoadImage]="reviewer.image_key" [loadImage]="loadImage.bind(this)" alt="{{ reviewer.first_name }}" [src]="imageCache[reviewer.image_key]" />
                  {{ reviewer.first_name }} {{ reviewer.last_name }}
                </mat-option>
              </mat-optgroup>

            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="order-review-header__switch-wrapper">
          <label class="switch">
            <input type="checkbox" [(ngModel)]="toggleState" [disabled]="!formHtml" (click)="changePdfDisplayState()">
            <span class="slider round"></span>
          </label>
          <small>Show PDF Document</small>
        </div>










      </div>

      <app-pdf-view
        [expendInfo]="expendInfo"
        [orderReviewHeaderData]="orderReviewHeaderData"
        [verifiedAddress]="addressVerified"
        [clientDetail]="clientDetail"
        [clientOnReportDetail]="clientOnReportDetail"
      ></app-pdf-view>

      <app-sub-forms-view
        *ngIf="!oldOrders"
        [findingForm]="getFindingForm"
        [reviewId]="reviewId"
        (formChange)="onFormChange($event)"
        (filteredForms)="getFilteredForms($event)"
      ></app-sub-forms-view>

      <div class="order-review__pdfbody">

        <app-pdf-viewer
          [pdfUrl]="pdfUrl"
          [hidden]="!toggleState"
        ></app-pdf-viewer>

      <div
      style="height: calc(100vh - 240px); overflow-y: auto"
        id="htmlContainer"
        [ngClass]="{ 'old-order': oldOrders }"
        [hidden]="toggleState"
      >
        <div [innerHTML]="formHtml"></div>
      </div>
    </div>
  </div>
</div>
