<div class="revision-request">
  <div class="revision-request__headline-wrap">
    <h5>{{ stepForRevision === 1 ? 'Message for client' : 'Revision Message' }}</h5>
  </div>
  <div class="revision-request__main">
    <div class="revision-request__revison-greetings" *ngIf="stepForRevision === 0">
      <p>Hello,</p>
      <p>
        After review, the following revisions are being requested by our team:
      </p>
      <div class="revision-request__revision-msg-box">
        <ol>
          <ng-container *ngIf="originalFindingsData && originalFindingsData.length > 0">
            <ng-container *ngFor="let finding of originalFindingsData; let i = index">
              <ng-container *ngIf="finding?.review_finding_status?.name === findingStatus.REJECTED">
                <li style="padding-bottom: 0px;">
                  <div class="comment">
                    <div><span>{{ finding?.comment }}</span></div>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ol>
      </div>
    </div>

    <div class="revision-request__revison-greetings" *ngIf="stepForRevision === 1">
      <p>Hello Team,</p>
      <p>
        A revision request has been sent to the appraiser regarding this order. Once we receive the
        revised report or communication from the appraiser we will update accordingly.
      </p>
      <p>Thank you</p>
    </div>
  </div>
</div>

<div [hidden]="true" class="sendMessagetoAppraiser">
  <div style="display: flex;flex-direction: column; ">
    <p style="padding: 0px; margin-top: 10px;">Hello,</p>
    <p style="padding: 0px;">
      After review, the following revisions are being requested by our team:
    </p>
    <ol>
      <ng-container *ngIf="originalFindingsData && originalFindingsData.length > 0">
        <ng-container *ngFor="let finding of originalFindingsData; let i = index">
          <ng-container *ngIf="finding?.review_finding_status?.name === findingStatus.REJECTED">
            <li style="list-style: auto;padding-bottom: 0px;">
              <div class="comment" style="display: flex;padding-top: 0px;margin-left: 20px;">
                <div><span>{{ finding?.comment }}</span></div>
              </div>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
    </ol>
  </div>
</div>

<div [hidden]="true" class="sendMessagetoClient">
  <div style="display: flex;flex-direction: column; ">
    <p>Hello Team,</p>
    <p>
      A revision request has been sent to the appraiser regarding this order. Once we receive the
      revised report or communication from the appraiser we will update accordingly.
    </p>
    <p>Thank you</p>
  </div>
</div>
