import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ReviewFinding } from '../../orders.interface';
import { FindingStatues } from 'src/app/shared/enums';

@Component({
  selector: 'app-revision-request',
  templateUrl: './revision-request.component.html',
  styleUrls: ['./revision-request.component.scss'],
})
export class RevisionRequestComponent {
  findingStatus = FindingStatues;
  @Input() originalFindingsData: ReviewFinding[] = [];
  @Input() sortRevisionArray: ReviewFinding[] = [];
  @Input() draggedFindings: any;
  @Input() stepForRevision = 0;
  @Output() sortedArray = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.originalFindingsData && this.originalFindingsData.length > 0) {
      this.originalFindingsData = this.originalFindingsData.filter((finding: any) => !!finding?.comment && finding?.review_finding_status?.name === 'Rejected');
    }

    if (this.sortRevisionArray && this.sortRevisionArray.length > 0) {
      this.originalFindingsData = this.sortRevisionArray.filter((finding: any) => !!finding?.comment && finding?.review_finding_status?.name === 'Rejected');
    }

    if (changes['draggedFindings'] && !changes['draggedFindings'].firstChange) {
      const previousDraggedFindings = changes['draggedFindings'].previousValue;
      const currentDraggedFindings = changes['draggedFindings'].currentValue;
      if (previousDraggedFindings !== currentDraggedFindings) {
        this.moveObject(this.originalFindingsData, currentDraggedFindings?.previousIndex, currentDraggedFindings?.currentIndex);
      }
    }
  }
  

  moveObject(array: any, previousIndex: number, currentIndex: number) {
    if (currentIndex >= array.length) {
        let k = currentIndex - array.length;
        while ((k--) + 1) {
            array.push(undefined);
        }
    }
    array.splice(currentIndex, 0, array.splice(previousIndex, 1)[0]);
    this.sortedArray.emit({ array, lastIdRemove: true });
    return array;
  }
}
